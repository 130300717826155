<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    width="80%"
    v-model="visible"
    :maskClosable="false"
    :footer="null"
  >
    <!--    @ok="toSubmit"-->
    <!--    okText="提交审核"-->

    <a-tabs type="card">
      <a-tab-pane key="1" tab="基本信息">
        <a-form-model
          ref="form"
          :model="rowData"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-divider orientation="left">基本信息</a-divider>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="经销商" prop="dealerName">
                <SelectDealerModal style="width: 300px"
                  :value.sync="rowData.dealerName"
                  :officeId="rowData.firmId"
                  @select="toSelectConsumer"
                ></SelectDealerModal>
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="发货性质" prop="deliveryProperties">
                <a-radio-group
                  :options="deliveryPropertiesOptions"
                  v-model="rowData.deliveryProperties"
                />
              </a-form-model-item>
            </a-col>
            <!--            <a-col :md="12">-->
            <!--              <a-form-model-item label="合同类型" prop="contractType">-->
            <!--                <a-select-->
            <!--                  :disabled="handle === 'edit' ? true : false"-->
            <!--                  placeholder="请选择合同类型"-->
            <!--                  v-model="rowData.contractType"-->
            <!--                >-->
            <!--                  <a-select-option :value="item.id" v-for="(item, index) in contractTypeList" :key="index">-->
            <!--                    {{ item.title }}-->
            <!--                  </a-select-option>-->
            <!--                </a-select>-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->
          </a-row>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="购货单位" prop="consigneeType">
                <a-radio-group
                  @change="handleChange"
                  :options="consigneeOptions"
                  v-model="rowData.consigneeType"
                />
              </a-form-model-item>
            </a-col>

            <!--            <a-col :md="12">-->
            <!--              <a-form-model-item label="购销合同类型" prop="purchaseAndSalesContractType">-->
            <!--                <a-radio-group-->
            <!--                  :disabled="handle === 'edit' ? true : false"-->
            <!--                  :options="purchaseAndSalesContractTypeOptions"-->
            <!--                  v-model="rowData.purchaseAndSalesContractType"-->
            <!--                />-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->

            <a-col :md="12" v-if="checkRadio == 1">
              <a-form-model-item label="联系人" prop="companyName">
                <a-input style="width: 200px" v-model="rowData.companyName" placeholder="请输入联系人"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row>
            <!--            <a-col :md="12">-->
            <!--              <a-form-model-item label="是否包邮" prop="flagFreeShipping">-->
            <!--                <a-radio-group-->
            <!--                  :disabled="handle === 'edit' ? true : false"-->
            <!--                  :options="flagFreeShippingOptions"-->
            <!--                  v-model="rowData.flagFreeShipping"-->
            <!--                />-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->
          </a-row>

          <a-row>
            <!-- <a-col :md="12">
              <a-form-model-item label="供应商" prop="supplier" :autoLink="false" ref="supplier">
                <a-select
                  :disabled="handle === 'edit' ? true : false"
                  @change="onChange"
                  labelInValue
                  placeholder="请选择供应商"
                  v-model="rowData.supplier"
                >
                  <a-select-option :value="item.id" v-for="(item, index) in supplierList" :key="index">
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col> -->

            <!--            <a-col :md="12">-->
            <!--              <a-form-model-item label="期望到货日期">-->
            <!--                <DatePicker-->
            <!--                  :disabled="handle === 'edit' ? true : false"-->
            <!--                  :startTime.sync="rowData.expectTime"-->
            <!--                ></DatePicker>-->
            <!--              </a-form-model-item>-->
            <!--            </a-col>-->

            <!-- <a-col :md="12">
              <a-form-model-item
                icon="down"
                label="仓库"
                class="required_fields"
                :prop="changeRadio == 1 ? 'warehouseId' : ''"
                v-if="changeRadio == 1"
                :autoLink="false"
                ref="test"
              >
                <a-select
                  :disabled="handle === 'edit' ? true : false"
                  labelInValue
                  placeholder="请选择仓库"
                  @change="handleWarehouseChange"
                  v-model="rowData.warehouse"
                >
                  <a-select-option :value="item.id" v-for="(item, index) in warehouseList" :key="index">
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col> -->
          </a-row>

          <!--                  <a-row>-->
          <!--                    <a-col :md="12">-->
          <!--                        <a-form-model-item label="收货人" prop="customerName">-->
          <!--                            <a-input :disabled="handle === 'edit' ? true : false" v-model="rowData.customerName" placeholder="客户名"></a-input>-->
          <!--                        </a-form-model-item>-->
          <!--                    </a-col>-->
          <!--                    <a-col :md="12">-->
          <!--                        <a-form-model-item label="联系方式" prop="customerContact">-->
          <!--                            <a-input :disabled="handle === 'edit' ? true : false" v-model="rowData.customerContact" placeholder="客户联系方式"></a-input>-->
          <!--                        </a-form-model-item>-->
          <!--                    </a-col>-->
          <!--                  </a-row>-->
          <!--                  <a-row>-->
          <!--                    <a-col :md="12">-->
          <!--                      <a-form-model-item label="收货地址" prop="provinceId">-->
          <!--                        <PCD-->
          <!--                          ref="pcd"-->
          <!--                          placeholder="请选择区域"-->
          <!--                          :province.sync="rowData.provinceName"-->
          <!--                          :city.sync="rowData.cityName"-->
          <!--                          :district.sync="rowData.areaName"-->
          <!--                          :provinceId.sync="rowData.provinceId"-->
          <!--                          :cityId.sync="rowData.cityId"-->
          <!--                          :districtId.sync="rowData.areaId"-->
          <!--                          :disabled="handle === 'edit' ? true : false">-->
          <!--                        </PCD>-->
          <!--                      </a-form-model-item>-->
          <!--                    </a-col>-->
          <!--                    <a-col :md="12">-->
          <!--                        <a-form-model-item label="详细收货地址" prop="address">-->
          <!--                            <a-input :disabled="handle === 'edit' ? true : false" v-model="rowData.address" placeholder="详细收货地址"></a-input>-->
          <!--                        </a-form-model-item>-->
          <!--                    </a-col>-->
          <!--                  </a-row>-->

          <a-row v-if="rowData.dealerName != null">
            <a-col :md="12">
              <!--<a-icon type='environment' />-->
              <a-form-model-item label="寄送至：">
                <a-row>
                  <a-col :md="18">
                    {{ rowData.customerName }} {{ rowData.customerContact }}
                    <a v-if="rowData.customerName == null" @click="onCheckAddress">新建收货地址</a>
                  </a-col>
                  <a-col :md="6">
                    <a @click="onCheckAddress" v-if="rowData.customerName != null">重新选择地址</a>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :md="24">
                    {{ rowData.provinceName }}{{ rowData.cityName }}{{ rowData.areaName }}{{ rowData.address }}
                  </a-col>
                </a-row>
              </a-form-model-item>
            </a-col>
            <a-col :md="12"> </a-col>
          </a-row>
          <a-row>
            <!-- <a-col :md="12">
                      <a-form-model-item label="订单类型" prop="orderType">
                        <a-select :disabled="handle === 'edit' ? true : false" placeholder="请选择订单类型" v-model="rowData.orderType">
                          <a-select-option :value="item.id" v-for="(item, index) in orderTypeList" :key="index">{{item.title}}</a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </a-col> -->
          </a-row>

          <a-row>
            <a-col :md="12">
              <a-form-model-item label="下单时间">
                <DatePicker style="width: 200px"
                  :startTime.sync="rowData.orderDate"
                ></DatePicker>
              </a-form-model-item>
            </a-col>
            <a-col :md="12">
              <a-form-model-item label="运费支付方式" prop="freightPayType">
                <a-select style="width: 200px" placeholder="请选择运费支付方式" v-model="rowData.freightPayType">
                  <a-select-option :value="item.id" v-for="(item, index) in freightPayTypeList" :key="index">{{item.title}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :md="24">
              <a-form-model-item label="订单备注" prop="" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-textarea
                  :disabled="handle === 'edit' ? true : false"
                  v-model="rowData.orderRemark"
                  placeholder=""
                ></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-divider orientation="left">订单附件</a-divider>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="上传附件">
                <QiniuUpload
                  :disabled="handle === 'edit' || handle === 'add'  ? false : true"
                  type="image"
                  :value.sync="imageUrl"
                  :size="5"
                ></QiniuUpload>
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-divider orientation="left">商品信息</a-divider>
          <a-row>
            <a-col :md="24">
              <a-form-model-item label="添加商品" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                <a-button type="primary" @click="onOpenProModal()">添加商品</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>



          <div class="table_outer" v-if="productList.length > 0">
            <a-table :scroll="{ x: 1970 }" :data-source="productList" bordered :rowKey="(record) => record.id" :pagination="false">
              <a-table-column title="商品图片" data-index="imgUrl" :width="100" :ellipsis="true" align="center" fixed="left"  >
                <template slot-scope="text, row">
                  <ZoomMedia style="height: 50px" :value="row.imgUrl"></ZoomMedia>
                </template>
              </a-table-column>
              <a-table-column
                title="商品名称"
                data-index="name"
                :width="200"
                align="left"
                fixed="left"
              ></a-table-column>
              <a-table-column
                title="品牌"
                data-index="brandName"
                :width="120"
                :ellipsis="true"
                align="center"
              ></a-table-column>
              <a-table-column
                title="商品分类"
                data-index="categoryName"
                :width="120"
                :ellipsis="true"
                align="center"
              ></a-table-column>
              <a-table-column title="规格" data-index="specStr" :width="120" align="center"></a-table-column>
              <a-table-column title="型号" data-index="barCode" :width="120" align="center"></a-table-column>
              <a-table-column
                title="单位"
                data-index="proUnitVo.name"
                :width="150"
                :ellipsis="true"
                align="center"
              >
              <template slot-scope="text, row">
                 <span v-if="row.proUnitVo.point != 0">{{row.proUnitVo.name}} <span>(保留{{row.proUnitVo.point}}小数)</span></span>
                  <span v-if="row.proUnitVo.point == 0">{{row.proUnitVo.name}} <span>(保留整数)</span></span>
                  </template>
              </a-table-column>

              <a-table-column title="数量" data-index="purchaseNum" :width="140" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  <a-input-number @change="getRabateList" v-model="row.purchaseNum" :step="setp" @blur="blurPurchaseNum" :min="0"></a-input-number>
                </template>
              </a-table-column>
              <a-table-column
                title="标准开票价（D价）"
                data-index="sellPrice"
                :width="150"
                :ellipsis="true"
                align="right"
              >
                <template slot-scope="text">{{ text.toFixed(2) }}</template>
              </a-table-column>
              <a-table-column title="撮合单价" data-index="proMatchPrice" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  <a-input-number v-model="row.proMatchPrice" @blur="blurPurchasePrice" @change="getRabateList" :min="1"></a-input-number>
                  
                  </template>
              </a-table-column>
              <a-table-column title="申请单价" data-index="realPirce" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  {{row.realPirce.toFixed(2)}}
                  </template>
              </a-table-column>

              <a-table-column
                title="已使用折扣金额"
                data-index="usedRebateTotal"
                :width="120"
                :ellipsis="true"
                align="right"

              >
                <template slot-scope="text">{{ text.toFixed(2) }}</template>
              </a-table-column>
              <a-table-column title="D价合计" data-index="" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  {{ (row.sellPrice * row.purchaseNum).toFixed(2) }}
                </template>
              </a-table-column>
              <a-table-column title="实付合计" data-index="" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  {{ (row.realPirce * row.purchaseNum - row.usedRebateTotal).toFixed(2) }}
                </template>
              </a-table-column>
              <a-table-column title="实际折扣率" data-index="" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  {{
                    ((
                      (row.realPirce * row.purchaseNum - row.usedRebateTotal) /
                      (row.sellPrice * row.purchaseNum)
                    )*100)
                  }}%
                </template>
              </a-table-column>
              <a-table-column title="政策" data-index="" :width="300" :ellipsis="true" align="center">
                <template slot-scope="text, row, index">
                  <a-select
                    placeholder="请选择"
                    v-if="row.policyInfoVOS != null"
                    style="width: 100%"
                    v-model="row.optKey"
                    @change="onPolicySelectChange(index)"
                  >
                    <a-select-option :value="item.optKey" v-for="(item, index) in row.policyInfoVOS" :key="index">
                      <span v-if="item.discountNum == 1">{{ item.name }}</span>
                      <span v-if="item.discountNum != 1"
                        >{{ item.name }}（D - {{ 100 - Number(item.discountNum * 100) }}%）</span
                      >
                    </a-select-option>
                  </a-select>
                  <span v-else>--</span>
                </template>
              </a-table-column>
              <a-table-column title="机型二" data-index="" :width="150" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  <a-select placeholder="请选择" style="width: 100%" v-model="row.modelTwo">
                    <a-select-option :value="item.id" v-for="(item, index) in modelTowList" :key="index">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </template>
              </a-table-column>
              <a-table-column title="备注" data-index="remark" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  <a-input v-model="row.remark" placeholder="请输入"></a-input>
                </template>
              </a-table-column>
              <a-table-column title="操作" data-index="action" :width="120" :ellipsis="true" align="center">
                <template slot-scope="text, row">
                  <a @click="onDelete(row.goodsId)">删除</a>
                </template>
              </a-table-column>
            </a-table>
          </div>
          <div class="table_outer" v-if="productList.length > 0">
            <div class="rebate">
              <div class="rebate-title">选择折扣：</div>
              <div style="display: flex; margin-top: 30px; align-items: flex-end">
                <div class="money-outer">
                  <div class="rebate-total-money">可用折扣金额：{{ canUsedRebateTotal }}</div>
                  <!-- <div class="rebate-order-money">
                    当前订单最高可用折扣金额： {{ orderUsableRebateTotal.toFixed(2) }}
                  </div> -->
                </div>
                <div class="btns" @click="onChoose()"><u>选择其他折扣</u></div>
              </div>
            </div>
          </div>
          <div class="product_Amount_info" v-if="productList.length > 0">
            <div class="content">
              <div class="text"></div>
              <div class="text">
                <span>商品总数：</span><span>{{ totalQuantity }}</span
                ><span style="margin-left: 20px"> D价总金额：</span><span>{{ totalMoney.toFixed(2) }}</span>
              </div>
              <div class="text">
                <span>折扣：</span><span class="text-color">- {{ totalRebatePrice.toFixed(2) }}</span>
              </div>
              <div class="text">
                <span>政策：</span><span class="text-color">- {{ policyTotalPrice.toFixed(2) }}</span>
              </div>
              <div class="text">
                <span>实付金额：￥</span
                ><span class="text-color real-total-price">{{ realTotalPrice.toFixed(2) }}</span>
              </div>
            </div>
          </div>
        </a-form-model>
      </a-tab-pane>
      <a-tab-pane key="2" tab="操作日志" v-if="handle != 'add'">
        <log-page :orderId="orderId"></log-page>
      </a-tab-pane>
    </a-tabs>
    <ProductModal ref="ProductModal" @get-product-list="getProductList"></ProductModal>
    <ChooseRebate ref="ChooseRebate" @checkout="getRebateIds"></ChooseRebate>
    <CheckAddressModal ref="CheckAddressModal" @refresh="getEcho"></CheckAddressModal>

    <div class="footer-bts" v-if="handle != 'check'">
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="save" :loading="loading" @click="toSubmit(9)">保存草稿</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="toSubmit(0)">提交审核</a-button>
    </div>
  </a-modal>
</template>

<script>
import { editOrderInfo, selectByIdOrderInfo, addOrderInfo } from '../api/OrderInfoApi'
import ProductModal from './ProductModal.vue'
import ChooseRebate from './ChooseRebate.vue'
import LogPage from './LogPage.vue'
import { formatMoneyOfRound } from '../../../utils/util.js'
import SelectDealerModal from '../../common/SelectDealerModal.vue'
import CheckAddressModal from './CheckAddressModal.vue'

export default {
  components: {
    ProductModal,
    ChooseRebate,
    LogPage,
    SelectDealerModal,
    CheckAddressModal,
  },
  data() {
    return {
      timeout:null,
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        dealerName: [{ required: true, message: '请选择经销商', trigger: 'change' }],
        firmName: [{ required: true, message: '请选择事务所', trigger: 'change' }],
        companyName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        consigneeType: [{ required: true, message: '请选择购货单位', trigger: 'change' }],
        //flagFreeShipping: [{ required: true, message: '请选择包邮类型', trigger: 'change' }],
        deliveryProperties: [{ required: true, message: '请选择发货性质', trigger: 'change' }],
        //purchaseAndSalesContractType: [{ required: true, message: '请选择购销合同类型', trigger: 'change' }],
        payType: [{ required: true, message: '请支付选择方式', trigger: 'change' }],
        //contractType: [{ required: true, message: '请选择合同类型', trigger: 'change' }],
        orderType: [{ required: true, message: '请选择订单类型', trigger: 'change' }],
        supplier: [{ required: true, message: '请选择供应商', trigger: 'change' }],
        // warehouseId: [{ required: true, message: '请选择仓库', trigger: 'change' }],
        taxType: [{ required: true, message: '请选择发票类型', trigger: 'change' }],
        taxTitle: [{ required: true, message: '请输入发票抬头', trigger: 'blur' }],
        taxCompany: [{ required: true, message: '请输入单位名称', trigger: 'blur' }],
        taxNum: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' }],
        freightPayType: [{ required: true, message: '请选择运费支付方式', trigger: 'change' }],
      },
      regionList: [],
      payTypeList: [],
      contractTypeList: [],
      orderTypeList: [],
      goodsChannelList: [],
      freightPayTypeList: [],
      consigneeOptions: [
        {
          label: '公司',
          value: 2,
        },
        {
          label: '个人',
          value: 1,
        },
      ],
      deliveryPropertiesOptions: [
        {
          label: '正常发货',
          value: 1,
        },
        {
          label: '赠送',
          value: 2,
        },
        {
          label: '售后',
          value: 3,
        },
      ],

      purchaseAndSalesContractTypeOptions: [
        {
          label: '个人',
          value: 1,
        },
        {
          label: '公司',
          value: 2,
        },
      ],
      productList: [], // 选中商品
      dealerList: [],
      dealerId: [],
      checkedList: [],
      totalQuantity: 0, // 总数量
      totalMoney: 0, // 总金额
      realTotalPrice: 0, // 实付金额
      totalRebatePrice: 0, // 折扣总金额
      policyTotalPrice: 0, // 政策总金额,
      orderUsableRebateTotal: 0, // 当前订单可使用折扣总额
      btnsLoading: false,
      checkedRebateList: [],
      checkRadio: 0,
      supplierList: [],
      warehouseList: [],
      changeRadio: 0,
      canUsedRebateTotal: 0,
      orderId: '',
      imageUrl: '',
      setp:'0.01',
      messageArr:'',
      modelTowList: [
        {
          id: 1,
          name: 1,
        },
        {
          id: 2,
          name: 2,
        },
        {
          id: 3,
          name: 3,
        },
        {
          id: 4,
          name: 4,
        },
        {
          id: 5,
          name: 5,
        },
        {
          id: 6,
          name: 6,
        },
      ],
      loading: false,
      row: {},
      currentTime:''
    }
  },
  computed: {},
  methods: {
    // 获取行数据
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.productList = []
      this.dealerId = []
      this.rowData = {}
      this.imageUrl = ''
      this.orderId = row.id
      // this.$nextTick(() => {
      //   this.$refs.pcd.setPCD()
      // })
      this.getEnumerationList()
      if (handle === 'add') {
        //this.$set(this.rowData, 'consigneeType', 1)
        this.$set(this.rowData, 'flagFreeShipping', true)
        this.$set(this.rowData, 'deliveryProperties', 1)
        this.$set(this.rowData, 'purchaseAndSalesContractType', 1)
        this.checkRadio = 0
        //this.$set(this.rowData, 'taxType', 1)
      } else if (handle === 'edit') {
        selectByIdOrderInfo(row.id).then((res) => {
          this.dealerId = [res.body.bigAreaId, res.body.firmId, res.body.dealerId]
          this.rowData = res.body
          //this.rowData.contractType = Number(res.body.contractType)
          this.rowData.orderType = Number(res.body.orderType)
          this.rowData.consigneeType = Number(res.body.consigneeType)
          this.rowData.companyName = res.body.companyName
          this.checkRadio = Number(res.body.consigneeType)
          this.rowData.deliveryProperties = Number(res.body.deliveryProperties)
          //this.rowData.purchaseAndSalesContractType = Number(res.body.purchaseAndSalesContractType)
          // this.productList = this.rowData.orderInfoDetailVOList
          //  console.log("99999",this.productList);
          this.imageUrl = res.body.filesVOS
            ? res.body.filesVOS
                .map((e) => {
                  return e.fileUrl
                })
                .toString()
            : ''

          const goodIds = this.rowData.orderInfoDetailVOList.map(item=>item.goodsId);
          //构建商品ID ，商品数量对象
          const goodsList = this.rowData.orderInfoDetailVOList.map(item=> {
            return{
              goodsId:item.goodsId,
              purchaseNum : item.purchaseNum
            }
          });

          if(goodIds){
            this.initProductList(this.rowData.dealerId,goodIds,goodsList,this.rowData.orderCode);
          }


          this.axios.get(`/api/dealer/dealer/dealerInfo/manager/selectDealerOtherByOrder/`+this.rowData.dealerId+'/'+this.rowData.orderCode).then((res) => {
            this.canUsedRebateTotal = res.body.canUsedRebateTotal
            //this.getEcho(res.body.defaultAddress)
          })

          // this.$nextTick(() => {
          //   this.$refs.pcd.setPCD()
          // })
        })
      }

      this.axios.get('/api/base/system/dictionary/selectByCode/'+ '运费支付方式').then(res => {  // 获取字典合同类型
        this.freightPayTypeList = res.body
      })

    },

    // 获取页面枚举数据
    getEnumerationList() {
      // this.axios.get('/api/firm/mhiac/mhiacArea/listAll').then((res) => {
      //   // 获取大区数据
      //   this.regionList = res.body
      // })
      // this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
      //   // 获取大区事务所经销商数据
      //   this.dealerList = res.body
      // })
      // this.axios.get('/api/base/system/dictionary/selectByCode/' + '合同类型').then((res) => {
      //   // 获取字典合同类型
      //   this.contractTypeList = res.body
      // })
      // this.axios.get('/api/base/system/dictionary/selectByCode/' + '订单类型').then((res) => {
      //   // 获取字典订单类型
      //   this.orderTypeList = res.body
      // })
      // this.axios.get('/api/base/system/dictionary/selectByCode/' + '供应商').then((res) => {
      //   // 获取字典供应商
      //   this.supplierList = res.body
      // })
      // this.axios.get('/api/base/system/dictionary/selectByCode/' + '仓库').then((res) => {
      //   // 获取字典仓库
      //   this.warehouseList = res.body
      // })
    },

    // 打开选择地址弹框
    onCheckAddress() {
      if (!this.rowData.dealerId) {
        return this.$message.warning('请选择经销商')
      }
      this.$refs.CheckAddressModal.show(this.rowData.dealerId, this.rowData.addressId)
    },

    // 收获类型为单位时打开弹框
    handleChange(e) {
      this.checkRadio = e.target.value
      if (this.checkRadio == 2) {
        this.$set(this.rowData, 'companyName', this.row.name)
      } else {
        this.$set(this.rowData, 'companyName', this.row.contacts)
      }
    },

    onChange(val) {
      this.$refs.supplier.onFieldChange()
      if (val.key == 11) {
        this.changeRadio = 1
      } else {
        this.changeRadio = 2
      }
      this.rowData.supplierName = val.label
      this.rowData.supplierId = val.key
    },

    onDealerChange(val, data) {
      this.$refs.test.onFieldChange()
      this.rowData.bigAreaId = val[0]
      this.rowData.bigAreaName = data[0].title
      this.rowData.firmId = val[1]
      this.rowData.firmName = data[1].title
      // this.rowData.dealerId = val[2]
      // this.rowData.dealerName = data[2].title
    },

    handleWarehouseChange(val) {
      this.$refs.test.onFieldChange()
      //this.$set(this.rowData, 'warehouseId', val.key)
      //this.$set(this.rowData, 'warehouseName', val.label)
      // this.rowData.warehouseId = val.key
      // this.rowData.warehouseName = val.label
    },

    toSelectConsumer(row) {
      this.row = row
      this.$set(this.rowData, 'dealerId', row.id)
      this.$set(this.rowData, 'dealerName', row.name)
      this.$set(this.rowData, 'companyName', row.name)
      this.$set(this.rowData, 'orderCode', row.orderCode)

      this.axios.get(`/api/dealer/dealer/dealerInfo/manager/selectDealerOtherByOrder/${row.id}/${row.orderCode}`).then((res) => {
        this.canUsedRebateTotal = res.body.canUsedRebateTotal
        this.getEcho(res.body.defaultAddress)
      })
      if (this.productList.length > 0) {
        let ids = this.productList.map((e) => e.id)
        let data = {
          dealerId: this.rowData.dealerId,
          goodsIds: ids,
          orderCode:this.rowData.orderCode
        }
        this.axios
          .post(`/api/order/order/orderInfo/queryProListAndPolicy`, data)
          .then((res) => {
            console.log(res)
            if (res.code === 200) {
              let tmpArr = res.body.orderInfoDetailGoodsVOS
              tmpArr.forEach((e) => {
                let isExist = false
                let policyInfoVOS = []
                this.productList.forEach((i) => {
                  if (e.id === i.id) {
                    isExist = true
                    policyInfoVOS = e.policyInfoVOS
                  }
                  if (isExist) {
                    i.policyInfoVOS = policyInfoVOS
                  }
                })
              })
              this.productList = tmpArr.map((e) => {
                return {
                  ...e,
                  goodsId: e.id,
                  optKey: e.policyInfoVOS != null ? e.policyInfoVOS[0].optKey : 0,
                  purchaseNum: 1,
                  realPirce: e.sellPrice,
                  remark: '',
                }
              })

            }
          })
          .catch((err) => {})
      }
    },

    // 把获取到的地址回显到页面
    getEcho(row) {
      if (row != null) {
        this.$set(this.rowData, 'customerName', row.contacts)
        this.$set(this.rowData, 'customerContact', row.phone)
        this.$set(this.rowData, 'provinceName', row.province)
        this.$set(this.rowData, 'provinceId', row.provinceId)
        this.$set(this.rowData, 'cityName', row.city)
        this.$set(this.rowData, 'cityId', row.cityId)
        this.$set(this.rowData, 'areaName', row.area)
        this.$set(this.rowData, 'areaId', row.areaId)
        this.$set(this.rowData, 'address', row.address)
        this.$set(this.rowData, 'addressId', row.id)
      } else if (row == null || !row) {
        this.$set(this.rowData, 'customerName', null)
        this.$set(this.rowData, 'customerContact', null)
        this.$set(this.rowData, 'provinceName', null)
        this.$set(this.rowData, 'provinceId', null)
        this.$set(this.rowData, 'cityName', null)
        this.$set(this.rowData, 'cityId', null)
        this.$set(this.rowData, 'areaName', null)
        this.$set(this.rowData, 'areaId', null)
        this.$set(this.rowData, 'address', null)
        this.$set(this.rowData, 'addressId', null)
      }

      // this.$nextTick(() => {
      //   this.$refs.pcd.setPCD()
      // })
    },

    // 监听当前选中数据
    onPolicySelectChange(index) {
      let tmp = this.productList[index]
      let tmpArr = tmp.policyInfoVOS.filter((x) => x.optKey === tmp.optKey)
      let discountNum = tmpArr.length > 0 ? tmpArr[0].discountNum : ''
      tmp.realPirce = tmp.sellPrice * discountNum
      this.$set(this.productList, index, tmp)
       this.getRabateList()
    },

    // 根据商品数量、政策的不同查询当前商品可用折扣和已使用折扣
    getRabateList() {
      let reqData = {
        dealerId: this.rowData.dealerId,
        orderInfoDetailDTOList: this.productList,
        orderCode:this.rowData.orderCode
        
      }

      this.axios.post('/api/order/order/orderInfo/getNewRebatePriceByChangPro', reqData).then((res) => {
        console.log("res33333",res)
        this.getProductData(res.body.orderInfoDetailGoodsVOS)
      })

      this.getTotalGoodsInfo()
    },

    // 根据商品数量、政策的不同查询当前商品可用折扣和已使用折扣
    getProductData(resList) {
      this.productList.forEach((e) => {
        let isExist = false
        let canUsedRebatePrice = 0
        let usedRebateTotal = 0

        if (resList.length > 0) {
          resList.forEach((i) => {
            if (e.id === i.id) {
              isExist = true
              canUsedRebatePrice = i.canUsedRebatePrice < 0 ? 0 : i.canUsedRebatePrice
              usedRebateTotal = i.usedRebateTotal < 0 ? 0 : i.usedRebateTotal
            }
            if (isExist) {
              e.canUsedRebatePrice = canUsedRebatePrice
              e.usedRebateTotal = usedRebateTotal
            } else {
              e.canUsedRebatePrice = 0
              e.usedRebateTotal = 0
            }
          })
        } else {
          this.productList = this.productList.map((e) => {
            return {
              ...e,
              canUsedRebatePrice: 0,
              usedRebateTotal: 0,
            }
          })
        }
      })
      this.getTotalGoodsInfo()
    },

    // 获取选中折扣
    getRebateIds(item, data) {
      this.checkedRebateList = item
      this.getProductData(data)
    },

    // 打开选择折扣弹框
    onChoose() {
      this.$refs.ChooseRebate.isShow(this.rowData.dealerId,this.rowData.orderCode, this.checkedRebateList, this.productList)
    },

    // 打开商品弹框
    onOpenProModal() {
      if (!this.rowData.dealerId) {
        return this.$notification.warning({ message: '请选择经销商' })
      }
      this.$refs.ProductModal.isShow(this.rowData.dealerId,this.rowData.orderCode, this.productList)
    },
    //编辑订单初始化产品
    //data：{"dealerId":18,"goodsIds":[70,10,9,8]}
    initProductList(dealerId,goodsIds,goodsList,orderCode){
      const tempData={dealerId:dealerId,goodsIds:goodsIds,goodsList:goodsList,orderCode:orderCode};
      this.axios
        .post(`/api/order/order/orderInfo/newQueryProListAndPolicy`, tempData)
        .then((res) => {
          if (res.code === 200) {
            this.dataList = res.body
            this.getProductListForEdit(this.dataList,goodsList);
          }
        })
        .catch((err) => {
        })
    },
    getProductList(data) {

      console.log("data",data)
      const arr = new Map()
      let vo = data.orderInfoDetailGoodsVOS.map((e) => {
        return {
          ...e,
          goodsId: e.id,
          optKey: e.policyInfoVOS != null ? e.policyInfoVOS[0].optKey : 0,
          purchaseNum:1,
          realPirce: e.sellPrice,
          remark: '',
          proMatchPrice:1,
        }
      })
      data.orderInfoDetailGoodsVOS.forEach(item => {
        let setp = item.proUnitVo.point == 0 ? 1 : 1/Math.pow(10,item.proUnitVo.point);
        this.setp = setp
      })

      const list = this.productList.concat(vo)
      // 最大折扣组合ID
      this.checkedRebateList = data.usedRebateIdList
      this.productList = list.filter((e) => !arr.has(e.goodsId) && arr.set(e.goodsId, 1))
      this.getTotalGoodsInfo()
      // 计算当前商品可用总折扣金额
      this.orderUsableRebateTotal = this.productList
        .map((e) => e.canUsedRebatePrice)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next))
    },


    /**
     * 2021-08-15  编辑商品携带数量
     */
    getProductListForEdit(data,goodsList) {
      const arr = new Map()
      const vo = data.orderInfoDetailGoodsVOS.map((e) => {
        return {
          ...e,
          goodsId: e.id,
          optKey: e.policyInfoVOS != null ? e.policyInfoVOS[0].optKey : 0,
          purchaseNum: 1,
          realPirce: e.sellPrice,
          remark: '',
          proMatchPrice:1,
        }
      })
      goodsList.forEach(function(item, index) {
        vo.forEach(function(item2, index2){
          if(item.goodsId == item2.goodsId){
            item2.purchaseNum = item.purchaseNum
          }
        })
      })

      const list = this.productList.concat(vo)
      // 最大折扣组合ID
      this.checkedRebateList = data.usedRebateIdList
      this.productList = list.filter((e) => !arr.has(e.goodsId) && arr.set(e.goodsId, 1))

      this.getTotalGoodsInfo()
      // 计算当前商品可用总折扣金额
      this.orderUsableRebateTotal = this.productList
        .map((e) => e.canUsedRebatePrice)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next))
    },




    // 获取商品价格汇总
    getTotalGoodsInfo() {
      this.totalQuantity = this.productList
        .map((e) => e.purchaseNum)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next)) // 总数量
      this.totalMoney = this.productList
        .map((e) => e.sellPrice * e.purchaseNum)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next)) // 总金额
      this.totalRebatePrice = this.productList
        .map((e) => e.usedRebateTotal)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next)) // 折扣优惠总金额
      const tmpPrice = this.productList
        .map((e) => e.purchaseNum * e.realPirce)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next)) // 政策后金额
      this.policyTotalPrice = formatMoneyOfRound(this.totalMoney) - formatMoneyOfRound(tmpPrice) // 政策优惠总金额
      this.realTotalPrice =
        formatMoneyOfRound(this.totalMoney) -
        formatMoneyOfRound(this.totalRebatePrice) -
        formatMoneyOfRound(this.policyTotalPrice) // 实付金额
      // 计算当前商品可用总折扣金额
      this.orderUsableRebateTotal = this.productList
        .map((e) => e.canUsedRebatePrice)
        .reduce((prev, next) => formatMoneyOfRound(prev) + formatMoneyOfRound(next))
    },

    // 删除选中的商品
    onDelete(id) {
      this.productList.splice(
        this.productList.findIndex((e) => {
          return e.goodsId === id
        }),
        1
      )
      this.getRabateList()
    },

    // 禁用弹框
    handleCancel() {
      this.visible = false,
      this.loading =false
    },
    blurPurchaseNum(){
      let itemArr = this.productList.map(item => {
            if(item.proUnitVo.point > 0){
             var s = '^\\d+(\\.\\d{1,'+item.proUnitVo.point+'})?$';
             this.messageArr = '请输入'+item.proUnitVo.name +'单位对应的'+ item.proUnitVo.point + '位小数';
            }else if(item.proUnitVo.point === 0){
              var s =  /^\d+$/;
              this.messageArr = '请输入'+item.proUnitVo.name +'单位对应的整数'
            }
             var re = new RegExp(s);


            return re.test(item.purchaseNum)
          })
           if(itemArr[0] === false){
               return this.$notification.warning({ message:this.messageArr})
           }
           let itemNum = this.productList.every((item)=>{
               return item.purchaseNum != 0
           })
           if(itemNum === false){
               return this.$message.warning('选择商品的数量不能为0')
           }
    },
    blurPurchasePrice(){
      let itemNum = this.productList.every((item)=>{
               return item.proMatchPrice && item.proMatchPrice != 0
           })
           if(itemNum === false){
               return this.$message.warning('申请单价不能为空，且不能为0')
           }
    },
    // 表单提交审核  || 保存草稿

    toSubmit(status) {

        if (!this.rowData.consigneeType) {
          //return this.$message.warning('请选择购货单位')
          this.$notification.warning({ message: '请选择购货单位' })
          return
        }
        if (!this.rowData.freightPayType) {
          this.$notification.warning({ message: '请选择运费支付方式' })
          return
        }
        this.$refs.form.validate(async (valid) => {
          if (!valid) {
            return
          }

          //this.rowData.orderInfoTax = {}
          //this.rowData.orderInfoTax.consigneeType = this.rowData.consigneeType
          this.rowData.usedRebateIds = this.checkedRebateList
          this.rowData.payAmount = this.realTotalPrice

          //  let itemArr = this.productList.map(item => {
          //   if(item.proUnitVo.point > 0){
          //    var s = '^\\d+(\\.\\d{1,'+item.proUnitVo.point+'})?$';
          //    this.messageArr = '请输入'+item.proUnitVo.name +'单位对应的'+ item.proUnitVo.point + '位小数';
          //   }else if(item.proUnitVo.point === 0){
          //     var s =  /^\d+$/;
          //     this.messageArr = '请输入'+item.proUnitVo.name +'单位对应的整数'
          //   }
          //    var re = new RegExp(s);
          //   return re.test(item.purchaseNum)
          // })
          //  if(itemArr[0] === false){
          //      return this.$notification.warning({ message:this.messageArr})
          //  }
           let itemNum = this.productList.every((item)=>{
               return item.purchaseNum != 0 && item.realPirce && item.realPirce != 0
           })
           if(itemNum === false){
               return this.$message.warning('选择商品的数量,申请单价不能为空，且不能为0')
           }
          this.rowData.orderInfoDetailDTOList = this.productList

          //设置订单为未审核状态
          this.rowData.orderStatus = status

          this.rowData.filesList = this.imageUrl.split(',').map((item) => {
            return {
              fileUrl: item,
            }
          })

          if (this.rowData.customerName == null) {
           
            this.$notification.warning({ message: '请选择收货地址' })
            return
          }

          if (this.rowData.orderInfoDetailDTOList.length <= 0) {
            
            this.$notification.warning({ message: '请至少添加一个商品' })
            return
          }

          // if (this.rowData.supplierId == 11) {
          //   if (!this.rowData.warehouseId) {
          //     return this.$message.warning('请选择仓库')
          //   }
          // }
          this.loading =true
         if(this.handle === 'add'){
            this.axios.post('/api/order/order/orderInfo/add',this.rowData).then(res=>{
                this.$notification.success({ message: res.message })
            this.$bus.$emit('getList')
            this.visible = false
            this.loading =false
            this.rowData = {}
            }).catch(err=>{
              this.loading =false
            })
          }else if(this.handle != 'add'){
            this.axios.post('/api/order/order/orderInfo/edit',this.rowData).then(res=>{
            this.$notification.success({ message: res.message })
            this.$bus.$emit('getList')
            this.visible = false
            this.loading =false
            this.rowData = {}
            }).catch(err=>{
              this.loading =false
            })
          }
        })

    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.table_outer {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}
.product_Amount_info {
  text-align: right;
  .content {
    .text {
      margin-left: 20px;
      line-height: 30px;
    }
    .text-color {
      color: red;
    }
    .real-total-price {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
.rebate {
  background: rgb(242, 242, 242);
  padding: 20px;
  width: 100%;
  height: 159px;
  .money-outer {
    color: rgb(88, 212, 88);
    width: 80%;
    padding-left: 50px;
  }
  .btns {
    cursor: pointer;
    color: rgb(22, 155, 213);
    font-weight: 600;
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
}
.required_fields:before {
  content: '*';
  color: red;
  margin-right: 4px;
  margin-bottom: -28px;
  margin-left: 90px;
}

.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>
